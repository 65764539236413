/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Image } from 'antd-mobile'
import { useSearchParams } from "react-router-dom";
import DocumentTitle from 'react-document-title'
import { getuserInfo, ActivityGiftTotal, checkActivity } from '../../services'
// import Poster from '../../components/Poster'
import styles from './styles.module.less'


export interface androidObj {
 
}

const AtciVity = () => {
  const [searchParams] = useSearchParams();
  const [user, setuser] = useState<any>({});
  const [paddingTop,setpaddingTop] = useState<any>(null)
  const [number, setnumber] = useState<any>('')
  const [startTime, setstartTime] = useState<string>('');
  const [endTime, setendTime] = useState<string>('');
  // const [visible, setVisible] = useState<boolean>(true)
  const userInfo = async () => {
    const userId: any = searchParams.get("userId");
    if (!userId) return
    const res = await getuserInfo(userId)
    console.log(res, 'res')
    if (res.code === 200) {
      setuser(res.data)
    }
  }
  const getActivityGiftTotal = async () => {
    const activityId: any = searchParams.get("activityId");
    // const belongToId: any = searchParams.get("belongToId");
    const userId: any = searchParams.get("userId");
    if (!activityId || !userId) return
    const res: any = await ActivityGiftTotal(activityId, userId)
    console.log(res)
    if (res.code === 200) {
      let number = 0;
      for(let i = 0; i<res.data.length; i++){
        number += res.data[i].giftNum
      }
      setnumber(res.data.length ? number : '0')
    }
  }
  const getcheckActivity = async () => {
    const activityId: any = searchParams.get("activityId");
    if (!activityId) return
    const res: any = await checkActivity(activityId)
    if (res.code === 200) {
      // + res.data.startTime.substr(10, res.data.startTime.length)
      // + res.data.endTime.substr(10, res.data.endTime.length)
      const sTime = res.data.startTime.substr(0, 10).split("-")[0] + "年" + res.data.startTime.substr(0, 10).split("-")[1] + "月" + res.data.startTime.substr(0, 10).split("-")[2] + "日";
      const eTime = res.data.endTime.substr(0, 10).split("-")[0] + "年" + res.data.endTime.substr(0, 10).split("-")[1] + "月" + res.data.endTime.substr(0, 10).split("-")[2] + "日";
      setstartTime(sTime)
      setendTime(eTime)

    }

  }
  //   const isiOS = () => {
  //     let u = navigator.userAgent;
  //     let iOs = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端\
  //     return iOs;
  // }

  const isAndroid = () => {
    const u = navigator.userAgent;
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    //let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    return isAndroid;
  }
  const share = () => {
    let markJson: any = {
      eventID: 'flagactivities_invite',
      jsonMap: {}
    }
    // markJson = JSON.stringify(markJson)
    // window.android.shareAndroidBaseurl(baseurl)
    if (isAndroid()) {
      // window.webkit.messageHandlers.shareIosBaseurl.postMessage({ 'param': '' });
      window.android.shareAndroidBaseurl()
      window.android.saveStatistics('flagactivities_invite','')
    } else {
      window.webkit.messageHandlers.shareIoscallBack.postMessage({ 'param': '' })
      window.webkit.messageHandlers.saveStatistics.postMessage(markJson)
    }
  }

  const link = () => {
    console.log(process.env.BASE_URL)
    let url = ''
    if (process.env.BASE_URL === 'http://dev.app.itianliao.com') {
      url = 'http://dev.h5.itianliao.com/'
    } else if (process.env.BASE_URL === 'http://pred.app.itianliao.com') {
      url = 'http://pred.h5.itianliao.com/'
    } else {
      url = 'https://h5.itianliao.com/'
    }
    let markJson: any = {
      eventID: 'flagactivities_list',
      jsonMap: {}
    }
    // markJson = JSON.stringify(markJson)
    if (isAndroid()) {
      // window.webkit.messageHandlers.shareIosBaseurl.postMessage({ 'param': '' });
      // window.android.shareAndroidlink({ name: '集旗榜', url: '/LinkUrl' })

      window.android.shareAndroidlink('集旗榜', url + 'LinkUrl?activityId=')
      window.android.saveStatistics('flagactivities_list', '')
    } else {
      window.webkit.messageHandlers.shareIoslink.postMessage({ name: '集旗榜', url: url + 'LinkUrl?activityId=' })
      window.webkit.messageHandlers.saveStatistics.postMessage(markJson)
    }
  }
  const backFun = () => {
    if (isAndroid()) {
      window.android.callBack()
    } else {
        window.webkit.messageHandlers.callBack.postMessage({ 'param': '' });
    }
  }
  useEffect(() => {
    if (isAndroid()) {
      setpaddingTop('30px')
    }
    userInfo()
    getActivityGiftTotal()
    getcheckActivity()
  }, [])
  return (
    <DocumentTitle title='国庆集国旗'>
      <div className={styles.AtciVity}>
        {/* 头部 */}
        <div className={styles.safeTop} style={paddingTop ? {paddingTop: paddingTop} : {}}>
          <div className={styles.head}>
            <div className={styles.backBox} onClick={backFun}>
              <Image className={styles.back} src={require('../../assets/back.png')} />
            </div>
            <div className={styles.title}>国庆集国旗</div>
          </div>
        </div>
        {/* 内容 */}
        <div className={styles.content}>
          <div className={styles.img1}>
            <Image src={require('../../assets/gqbg1.png')} />
          </div>
          <div className={styles.userInfo}>
            <div className={styles.left}>
              <img className={styles.via} src={user.avatarImg} alt="" />
              <div className={styles.name}>
                <span>{user.nickname}</span>
                <span>收到的国旗</span>
              </div>
            </div>
            <div className={styles.right}>
              <Image className={styles.icon} src={require('../../assets/WechatIMG637@2x.png')} />
              <div className={styles.num}>+{number || 0}</div>
            </div>
          </div>
          <div className={styles.details}>
            <div className={styles.titleicon}>
              <Image className={styles.icon} src={require('../../assets/atcivitytitle.png')} />
            </div>
            <div className={styles.tit}>
              活动主题
            </div>
            <div className={styles.text}>
              上天聊集国旗，为祖国庆生
            </div>
            <div className={styles.tit}>
              活动对象
            </div>
            <div className={styles.text}>
              全体用户
            </div>
            <div className={styles.tit}>
              活动周期
            </div>
            <div className={styles.text}>
              {startTime}-{endTime}
            </div>
            <div className={styles.tit}>
              活动规则
            </div>
            <div className={styles.text}>
              1、活动期间，主播在直播间、私信内收到打赏的国旗礼物（单价99聊币），可获得平台丰厚的流水奖=50%的礼物打赏分成+额外礼品奖励（礼品奖励见下表）。
            </div>
            <div className={styles.phoneimg}>
              <Image className={styles.img} src={require('../../assets/atcvityphone.png')} />
            </div>
            <div className={`${styles.text} ${styles.textleft}`}>
              <div>2、礼品奖励</div>
              <div className={styles.table_}>
                <ul>
                  <li>
                    <div>
                      <span>活动期间</span>
                      <span>集国旗总量(个)</span>
                    </div>
                    <div>
                      额外礼品奖励
                    </div>
                    <div>
                      礼物图片
                    </div>
                  </li>
                  <li>
                    <div>
                      300
                    </div>
                    <div>
                      <span>送6000声浪</span>
                      <span className={styles.feat}>价值¥300</span>
                    </div>
                    <div>
                      <Image className={styles.img} src={require('../../assets/goldcoin.png')} />
                    </div>
                  </li>
                  <li>
                    <div>
                      99
                    </div>
                    <div>
                      <span>送高级专属形象</span>
                      <span className={styles.feat}>专属头像框<br/>散发神秘魅力</span>
                    </div>
                    <div>
                      <Image className={styles.img} src={require('../../assets/crown.png')} />
                    </div>
                  </li>
                  <li>
                    <div>
                      38
                    </div>
                    <div>
                      <span>送专属形象</span>
                      <span className={styles.feat}>专属头像框<br/>散发神秘魅力</span>
                    </div>
                    <div>
                      <Image className={styles.img} src={require('../../assets/duke.png')} />
                    </div>
                  </li>
                  <li>
                    <div>
                      5
                    </div>
                    <div>
                      <span>送专属进场特效</span>
                      <span className={styles.feat}>炫酷的特效<br/>伴你高调入场</span>
                    </div>
                    <div>
                      <Image className={styles.unlike} src={require('../../assets/19@2x.png')} />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className={`${styles.text} ${styles.textleft}`}>
              <div>3、活动奖品在国庆结束后会生效。</div>
            </div>
          </div>
        </div>
        <div className={styles.bottomBtn}>
          <div onClick={link}>集旗榜单</div>
          <div onClick={share}>邀请助力</div>
        </div>
      </div>
    </DocumentTitle>
  )
}
export default AtciVity;